<template>
  <div class="examinerManagement">
    <div class="flex-box">
      <div class="left">
        <el-button type="success" @click="addPlanSummary">添加</el-button>
        <el-button type="primary">下载</el-button>
        <el-button type="danger">批量删除</el-button>
      </div>
      <div class="flex-box">
        <el-input
        clearable
        @clear="queryPlanSummary(1)"
          placeholder="请输入名称"
          class="picker"
          v-model="query.name"
        ></el-input>
        <el-select @clear="queryPlanSummary(1)" class="picker" v-model="query.semester" clearable placeholder="请选择类型">
            <el-option
            v-for="item in semesterList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
            </el-option>
        </el-select>

        <el-button type="primary" class="picker" @click="queryPlanSummary(1)"
          >查 询</el-button
        >
      </div>
    </div>
    <el-table :data="addPlanSummaryTable" style="width: 100%">
      <el-table-column
        label="名称"
        prop="name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="学期"
        prop="semester"
        align="center"
      ></el-table-column>
      <el-table-column
        label="上传人"
        prop="opername"
        align="center"
      ></el-table-column>

      <el-table-column label="操作" width="270px" prop="course" align="center">
        <template slot-scope="scope">
            <div>
                <el-button size="small" type="primary" @click="editPlanSummary(scope.row)"
                    >编辑
                </el-button>
                <el-button size="small" type="success"
                    >下载
                </el-button>
                <el-button size="small" type="danger" @click="deletePlanSummary(scope.row.id)"
                    >删除
                </el-button>
               
            </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="prev,pager,next"
      background
      :page-count="page.pageCount"
      :page-size="page.currentPage"
      @current-change="queryPlanSummary"
    ></el-pagination>

    <el-dialog
      :title="addPlanSummaryForm.id ? '修改' : '添加'"
      :visible.sync="addPlanSummaryDialogVisible"
      width="600px"
    >
      <div class="center">
        <el-form
          :model="addPlanSummaryForm"
          ref="addPlanSummaryFormRef"
          label-width="100px"
          :rules="addPlanSummaryFormRules"
        >
          <el-form-item label="名称：" prop="name">
            <el-input
              v-model="addPlanSummaryForm.name"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="地址：" prop="url">
            <el-input
              v-model="addPlanSummaryForm.url"
              placeholder="请输入地址"
            ></el-input>
          </el-form-item>

        </el-form>
      </div>
     
      <span slot="footer" class="dialog-footer">
        <el-button @click="addPlanSummaryDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="addPlanSummaryConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>


export default {
  
  name: "InformationPublish",
  data() {
    return {
      page: { pageCount: 1, currentPage: 0 },
      typeList:[
          {value:1,name:'校级'},
          {value:2,name:'部门级'},
      ],
      addPlanSummaryDialogVisible:false,
      addPlanSummaryForm:{
          id:'',
          name:'',
          url:'',
          // type:'',
      },
      addPlanSummaryFormRules:{
          name:[{required:true,message:'请输入名称',trigger:'blur'}],
        //   url:[{required:true,message:'请输入地址',trigger:'blur'}],
      },
      // 查询
        query:{
            name:'',
            semester:'',

        },
        addPlanSummaryTable:[],
        managementModule: JSON.parse(sessionStorage.getItem("userInfo")).nav1,
        semesterList: JSON.parse(sessionStorage.getItem("semesterList")),
        // roleType: false,
        role_type:'',     
        
    };
  },
  created() {
    //判断是否为管理员，1901为班级获奖页面id
    for (let i = 0; i < this.managementModule.length; i++) {
      if (this.managementModule[i].id === 1901) {
        // this.roleType = true;
        this.role_type = 1
        break;
      }else{
          this.role_type = 2
      }
    }

    this.queryPlanSummary(1);
    
  },
  methods: {
    // 查询计划总结
    queryPlanSummary(page){
        const that = this;
        let data = {
            indexNo: page,
            name: this.query.name,
            yearid: this.query.semester,
        };

      this.$post(`/base/querySchoolCultural.do`, data)
        .then((res) => {
            console.log(res)
          that.page.currentPage = res.data.indexNO;
          that.page.pageCount = res.data.pageCount;
        
          that.addPlanSummaryTable = res.data.rows;
          console.log(that.addPlanSummaryTable);
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 编辑
    editPlanSummary(row){
        // 显示对话框
      this.addPlanSummaryDialogVisible = true;
      // 把数据赋值给编辑表单
      this.$nextTick(() => {
        this.addPlanSummaryForm.id = row.id;
        this.addPlanSummaryForm.name = row.name;
        this.addPlanSummaryForm.url = row.url;
      });
    },
    // 添加总结
    addPlanSummary(){
        this.addPlanSummaryDialogVisible = true
        this.$nextTick(() => {
            this.$refs.addPlanSummaryFormRef.resetFields();
            this.addPlanSummaryForm.id = "";
        });
    },
    // 添加计划总结确定
    addPlanSummaryConfirm(){
        this.$refs.addPlanSummaryFormRef.validate((validate) => {
        if (validate) {
            // 编辑信息表单提交
            if (this.addPlanSummaryForm.id) {
                this.$post(
                    "/base/updateSchoolCultural.do",
                    this.addPlanSummaryForm
                ).then((res) => {
                    this.$message.success("编辑成功");
                    this.addPlanSummaryDialogVisible = false;
                    // 更新当前页数据列表
                    this.queryPlanSummary(this.page.currentPage);
                });
                return;
            }
            // 添加信息表单提交
            const data = {
                name: this.addPlanSummaryForm.name,
                url: this.addPlanSummaryForm.url,
            };
            console.log(data);
            this.$post("/base/insertSchoolCultural.do", data)
            .then((res) => {
                this.$message.success("添加成功");
                this.addPlanSummaryDialogVisible = false;
                this.queryPlanSummary(1);
                console.log(res);
            })
            .catch((err) => {
                // that.$message.error(err.message.data);
            });
        }
      });
    },
    deletePlanSummary(id){
        const that = this;
        this.$confirm("是否删除该条记录?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
        .then(() => {
            this.$post(`/base/deleteSchoolCultural.do?id=${id}`)
            .then((res) => {
                that.$message({
                    type: "success",
                    message: "删除成功!",
                });
                that.queryPlanSummary(this.page.currentPage);
            })
            .catch((err) => {
                that.$message.error(err.message);
            });
        })
        .catch(() => {
            this.$message({
                type: "info",
                message: "已取消删除",
            });
        });
    },

  },
};
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
</style>
